import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useAppDispatch } from "store/configureStore";
import { AuthClear } from "store/reducers/authentication/authentication";
import { useRouter } from "next/router";

import { useSession } from "next-auth/react";
import VisitKsa from "@components/visitKsa/Index";
import { setCartIsPaid } from "store/reducers/user";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
const Home = () => {
	const { t, i18n } = useTranslation("website");
	const router = useRouter();
	const { data: user } = useSession();
	const cartIsPaid = useSelector((state: RootState) => state?.user?.cartIsPaid);
	console.log(user, "admin userrrrrrrrrr");
	if (user) {
		console.log(user, "useruser2222222222222222222222222222222222222222222222");
		router.push("/admin/security/admins");
		// return false;
	} else {
		router.push("/auth/login");
	}
	// if (user && [0, 1]?.includes(+user?.userType)) {
	// 	router.push("/admin/security/admins");
	// 	return false;
	// }
	const dispatch = useAppDispatch();

	useEffect(() => {
		localStorage.removeItem("on_login_redirect_path");
		return () => {
			dispatch(AuthClear());
		};
	}, [router.pathname]);
	useEffect(() => {
		if (cartIsPaid) {
			dispatch(setCartIsPaid(false));
		}
	}, []);
	return (
		<div className="website-wrapper">
			{/* {currentTheme?.themeID === "66555608-d38c-4c30-939e-1ce5a8de7cf4" ? (
				<>
					<Header {...commonProps} />
					<div style={{ position: "relative" }}>
						<HomeHero {...commonProps} />
					</div>
					<PopularPackage {...commonProps} />
					<PlanTrip {...commonProps} /> 
					<RecommendStays {...commonProps} />
					{width > 768 && <StartTravelShop {...commonProps} />}
					{width > 768 && <Inspiration {...commonProps} />}
					<Footer {...commonProps} />
				</>
			) : (
				<VisitKsa />
				)} */}

			{/* <VisitKsa /> */}
		</div>
	);
};

export async function getServerSideProps(context: any) {
	return {
		props: {
			...(await serverSideTranslations(context?.locale, ["website"]))
		}
	};
}

export default Home;
